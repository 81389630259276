import Home from "../views/home/Home"

import PainelBI from "../views/paineis_bi/PainelBI";

import NaoEncontrado from "../views/NaoEncontrado";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

export default [
  {
    name: "Home",
    path: "/home",
    component: Home,
    meta: {
      raiz: true,
      requerPermissao: true,
      permissoes: ["sistemas"],
      label: "Home",      
    },
  },
  {
    name: "PainelBI",
    path: "/paineis_bi",
    component: PainelBI,
    meta: {
      raiz: false,      
      requerPermissao: true,
      permissoes: ["painel_bi"],
      label: "Painéis BI",      
    },
  },
  {
    name: "NaoEncontrado",
    path: "/nao-encontrado",
    component: NaoEncontrado,
  },
  {
    path: "*",
    redirect: (to) => {
      return { name: "NaoEncontrado", query: { url: to.path } };
    },
  },
];