import axios from "./axiosCustom";

class AuthApi {
    cancelTokens = {
        autenticar: null,
        sair: null
    };

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown);
    }

    async autenticar(login, senha) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.autenticar = source;

        const { data } = await axios.post(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/autenticar`,
            {
                login,
                senha
            },
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.autenticar = null;

        return data;
    }

    async sair() {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.sair = source;

        await axios.post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/autenticar`, {}, {
            cancelToken: source.token,
        });

        this.cancelTokens.sair = null;
    }
}

export default AuthApi;