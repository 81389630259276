import axios from "axios";
import axiosInstance from "./axiosInstance";

class HomeApi {
  cancelTokens = {
    getSistemasData: null,
    getUrlsData: null,
    validarAcesso: null,
    abrirNovaUrl: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getSistemasAdminData(idUsuario) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getSistemasAdminData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/sistemasAdminData`,
      {
        params: {
          idUsuario,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getSistemasAdminData = null;

    return data;
  }

  async getSistemasPublicosData(idUsuario) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getSistemasPublicosData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/sistemasPublicosData`,
      {
        params: {
          idUsuario,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getSistemasPublicosData = null;

    return data;
  }

  async getUrlsData(identificadorSistema, ambiente) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getUrlsData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/urls_sistemas`,
      {
        params: {
          identificadorSistema,
          ambiente,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getUrlsData = null;

    return data; 
  }

  async validarAcesso(sistemaSigla) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.validarAcesso = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/validar_acesso`,
      {
        params: {
          sistemaSigla,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.validarAcesso = null;

    return data;
  }

  async abrirNovaUrl(usuarioLogado, sistemaSigla, sistemaUrl) {
    const login = usuarioLogado.token[0].usuario.login;
    const authPrivate = usuarioLogado.auth_private;
    const authPublic = usuarioLogado.auth_public;
  
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/gerar`,
        {
          login,
          auth_private: authPrivate,
          auth_public: authPublic,
          sistema_sigla: sistemaSigla,
        }
      );
  
      const tokenCriptografado = encodeURIComponent(response.data.token);
      const iv = encodeURIComponent(response.data.iv);
  
      const URL = `${sistemaUrl}/sessoes/autenticar?token=${tokenCriptografado}&iv=${iv}`;
  
      const popup = window.open(URL, '_blank');

      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        alert("O pop-up foi bloqueado pelo navegador. Por favor, habilite pop-ups para acessar este sistema.");
      }

      popup.addEventListener("error", () => {
        alert("Ocorreu um erro ao tentar abrir o sistema. Por favor, tente novamente.");
      });
    } catch (error) {
      alert('Erro ao gerar o token temporário:', error)
    }
  }

  async getExpiraEm() {
    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/expira_em_minutos`
    );

    return data;
  }
}

export default HomeApi;
