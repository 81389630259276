import VueRouter from 'vue-router';

import Autenticar from '../views/sessoes/Autenticar';
import RecuperarSenha from '../views/sessoes/RecuperarSenha';
import TelaAvisoEmail from '../views/sessoes/TelaAvisoEmail';
import RedefinirSenha from '../views/sessoes/RedefinirSenha';
import SemPermissao from '../views/SemPermissao';

import Auth from '../services/Auth';
import rotasPrivadas from './rotasPrivadas';
import { rotaInicial } from './helpers';

const intersection = require('lodash.intersection');

const AUTENTICAR_ROUTE = 'Autenticar';
const RECUPERAR_SENHA_ROUTE = 'RecuperarSenha';
const TELA_AVISO_EMAIL_ROUTE = 'TelaAvisoEmail';
const REDEFINIR_SENHA_ROUTE = 'RedefinirSenha';
const SEM_PERMISSAO_ROUTE = 'SemPermissao';

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/sessoes/autenticar",
      component: Autenticar,
      name: AUTENTICAR_ROUTE,
      alias: "/",
    },
    {
      path: "/sessoes/recuperar_senha",
      component: RecuperarSenha,
      name: RECUPERAR_SENHA_ROUTE,
    },
    {
      path: "/sessoes/tela_aviso_email",
      component: TelaAvisoEmail,
      name: TELA_AVISO_EMAIL_ROUTE,
    },
    {
      path: "/sessoes/redefinir_senha",
      component: RedefinirSenha,
      name: REDEFINIR_SENHA_ROUTE,
    },
    {
      path: "/sem-permissao",
      component: SemPermissao,
      name: SEM_PERMISSAO_ROUTE,
    },
    ...rotasPrivadas,
  ],
  // base: `/portal/`
});

router.beforeEach((to, from, next) => {
    let auth = new Auth();
    let paginaAnterior = from.name;

    router.pagina_anterior = paginaAnterior;

    // se é pra rota de autenticar, mas ja esta logado
    if (to.name === AUTENTICAR_ROUTE && auth.loggedIn()) {
        // ENVIA PARA PRIMEIRA ROTA QUE TEM PERMISSAO
        const rota = rotaInicial();
        if (rota) {
            next({
                name: rota.name
            })
        } else {
            // não tem permissao em nenhuma rota
            // ATENCAO, POSSIVELMENTE OCORREU ERRO EM ALGUM LUGAR, NAO DEVERIA TER PASSADO PELO LOGIN DO SGU
            // SEM TER NENHUMA PERMISSAO
            next({
                name: SEM_PERMISSAO_ROUTE
            })
        }
    } else if (to.matched.some(record => record.meta.requerPermissao)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!auth.loggedIn()) {
            next({
                name: AUTENTICAR_ROUTE,
                query: { redirect: to.fullPath }
            })
        } else if (to.matched.some(record => intersection(record.meta.permissoes, auth.usuario.funcionalidades).length == 0)) {
            // não tem permissao
            next({
                name: SEM_PERMISSAO_ROUTE
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
});

export default router;