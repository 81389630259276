export default function(route) {
  return [
    {
      vmid: "og:url",
      property: "og:url",
      content: `${process.env.VUE_APP_BASE_URL}${route.fullPath}`,
    },
    {
      vmid: "og:type",
      property: "og:type",
      content: "article",
    },
    {
      vmid: "og:image:type",
      property: "og:image:type",
      content: "image/jpeg",
    },
    {
      vmid: "og:image:width",
      property: "og:image:width",
      content: "1201",
    },
    {
      vmid: "og:image:height",
      property: "og:image:height",
      content: "631",
    },
  ];
}
