import Auth from '../services/Auth';
import rotasPrivadas from './rotasPrivadas';

const intersection = require('lodash.intersection');

//retorna a rota inicial de um usuario de acordo com as permissoes que ele tem
//retorna null se usuario nao estiver logado
export const rotaInicial = () => {
    let auth = new Auth();
    if (!auth.loggedIn()) {
        return null;
    }

    return rotasPrivadas.find((rota) => rota.meta.requerPermissao && rota.meta.raiz
        && intersection(rota.meta.permissoes, auth.usuario.funcionalidades).length > 0);
}