<template>
  <div>
    <div class="tela-login">
      <div class = "logo_mobile">
        <img src="../../assets/imagens/logo-portal-bco.svg" alt=" ">
      </div>
      <div class="right">
        <h1>Link enviado!</h1>
        <form  @submit.prevent="handleSubmit">
            <div class="item">
                <p>
                  Enviamos um link para o e-mail cadastrado. Por favor, acesse-o para redefinir sua senha.
                </p>
            </div>
            <div class="item">
              <p class="aviso">
                Lembre-se: o link será válido por 24 horas!
              </p>
            </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img src="../../assets/imagens/logo-casa-bco2.svg" class="logo" alt="Logo da Casa Civil e Estado de Goiás" />
      </div>
    </div>
  </div>
</template>

<script>
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'TelaAvisoEmail',
    metaInfo() {
      return {
        title: "Portal",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Portal - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 800px) {
    .tela-login {
      display: grid;
      background-color: #125b19;
      min-height: 100vh;
      justify-items: center;
      align-content: space-evenly;

      .logo_mobile {
        img {
          width: 10rem;
          margin: 0;
        } 
      }

      .right {
        display: grid;
        grid-template-rows: .5fr 1.5fr;
        background-color: white;
        padding: 1rem;
        width: 90%;
        max-width: 35rem;
        height: 20rem;
        border-radius: 2rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

        h1 {
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
          align-self: center;
          font-size: calc(1.2rem + 1vw);
        }

        form {
          display: grid;
          align-content: space-evenly;
        }

        .item {
          display: grid;
          margin: .5rem 0;

          label {
            font-family: "Montserrat-Medium";
            color: #005516;
          }

          .aviso {
            text-align: center;
            color: red;
            font-size: 1.1rem;
          }

          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }

          input {
            height: 2.5rem;
            width: 100%;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: .9rem;
          }
        }

        a {
          margin: 0 0 0 .5rem;
          color: #005516;
          text-align: left;
          font-size: .9rem;
          width: fit-content;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        .bt-entrar {
          text-align: center;
          margin: 1rem 0;

          button {
            background-color: #005516;
            color: white;
            font-family: 'Montserrat-Medium';
            font-size: 1.1rem;
            border: none;
            padding: .5rem 1.5rem;
            border-radius: 1rem;
            cursor: pointer;
            min-width: 10rem;
            width: fit-content;
          }

          button:hover {
            background-color: #005517d5;
          }

          button:disabled {
            background-color: rgba(71, 69, 69, 0.455);
          }

          img {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: .4rem;
          }
        }
      }

      .footer-mobile {
        img {
          width: 28rem;
          margin: .5rem 0 0 -.3rem;
        }
      }
    }
  }
  @media screen and(min-width: 500px) and (max-width: 799px) {
    .tela-login {
      display: grid;
      background-color: #125b19;
      min-height: 100vh;
      justify-items: center;
      align-content: space-evenly;

      .logo_mobile {
        img {
          width: 10rem;
          margin: 0;
        } 
      }

      .right {
        display: grid;
        grid-template-rows: .5fr 1.5fr;
        background-color: white;
        padding: 1rem;
        width: 90%;
        max-width: 35rem;
        height: 20rem;
        border-radius: 2rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

        h1 {
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
          align-self: center;
          font-size: calc(1.2rem + 1vw);
        }

        form {
          display: grid;
          align-content: space-evenly;
        }

        .item {
          display: grid;
          margin: .5rem 0;

          label {
            font-family: "Montserrat-Medium";
            color: #005516;
          }

          .aviso {
            text-align: center;
            color: red;
            font-size: 1.1rem;
          }

          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }

          input {
            height: 2.5rem;
            width: 100%;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: .9rem;
          }
        }

        a {
          margin: 0 0 0 .5rem;
          color: #005516;
          text-align: left;
          font-size: .9rem;
          width: fit-content;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        .bt-entrar {
          text-align: center;
          margin: 1rem 0;

          button {
            background-color: #005516;
            color: white;
            font-family: 'Montserrat-Medium';
            font-size: 1.1rem;
            border: none;
            padding: .5rem 1.5rem;
            border-radius: 1rem;
            cursor: pointer;
            min-width: 10rem;
            width: fit-content;
          }

          button:hover {
            background-color: #005517d5;
          }

          button:disabled {
            background-color: rgba(71, 69, 69, 0.455);
          }

          img {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: .4rem;
          }
        }
      }

      .footer-mobile {
        img {
          width: 28rem;
          margin: .5rem 0 0 -.3rem;
        }
      }
    }
  }
  @media screen and(min-width: 60px) and (max-width: 499px) {
    .tela-login {
      display: grid;
      background-color: #125b19;
      min-height: 100vh;
      justify-items: center;
      align-content: space-evenly;

      .logo_mobile {
        img {
          width: 8rem;
          margin: 0;
        } 
      }

      .right {
        display: grid;
        grid-template-rows: .5fr 1.5fr;
        background-color: white;
        padding: 1rem;
        width: 90%;
        max-width: 35rem;
        height: 20rem;
        border-radius: 2rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

        h1 {
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
          align-self: center;
          font-size: calc(1.2rem + 1vw);
        }

        form {
          display: grid;
          align-content: space-evenly;
        }

        .item {
          display: grid;
          margin: .5rem 0;

          label {
            font-family: "Montserrat-Medium";
            color: #005516;
          }

          .aviso {
            text-align: center;
            color: red;
            font-size: 1.1rem;
          }

          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }

          input {
            height: 2.5rem;
            width: 100%;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: .9rem;
          }
        }

        a {
          margin: 0 0 0 .5rem;
          color: #005516;
          text-align: left;
          font-size: .9rem;
          width: fit-content;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        .bt-entrar {
          text-align: center;
          margin: 1rem 0;

          button {
            background-color: #005516;
            color: white;
            font-family: 'Montserrat-Medium';
            font-size: 1.1rem;
            border: none;
            padding: .5rem 1.5rem;
            border-radius: 1rem;
            cursor: pointer;
            min-width: 10rem;
            width: fit-content;
          }

          button:hover {
            background-color: #005517d5;
          }

          button:disabled {
            background-color: rgba(71, 69, 69, 0.455);
          }

          img {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: .4rem;
          }
        }
      }

      .footer-mobile {
        img {
          width: 23rem;
          margin: .5rem 0 0 -.3rem;
        }
      }
    }
  }
</style>